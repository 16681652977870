import './styles/style.scss';
import 'antd/dist/reset.css';
import { BrowserRouter } from "react-router-dom";
import AppRoute from './routes/routes';
import { WhatsAppIcon } from './constant/images';
import { FloatButton } from 'antd';
import { ImArrowUp } from "react-icons/im";

function App() {
  return (
    <BrowserRouter>
        <AppRoute />
        <div className="whatimg">
            <a href="https://web.whatsapp.com/send?phone=8559055905" target="_blank"> 
              <img className="whats-img smooth-zigzag-anim-1" src={WhatsAppIcon} alt="icon" /> 
            </a>
        </div>
        {/* <Chatbot /> */}
        <FloatButton.BackTop  className='back-to-top-button' icon={<ImArrowUp />} />
    </BrowserRouter>
  );
}

export default App;
