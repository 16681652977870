import React, { useState } from 'react';
import { Button, Form, Input, Upload, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import * as UserService from '../../services/userService';

const ProfileForm = ({ closeModal, userData }) => {
    const [form] = Form.useForm();
    var dispatch = useDispatch();
    const user_id = localStorage.getItem("user_id");

    const [userInput, setUserInput] = useState({
        username: userData ? userData?.username : "",
        email: "",
        phone: userData ? userData?.phone : "",
        address: userData ? userData?.address : "",
    });

    const [fileList, setFileList] = useState([]);

    const handleInput = (e) => {
        setUserInput({ ...userInput, [e.target.name]: e.target.value });
    };

    const onFinish = async (e) => {

        try {
            await form.validateFields(
                [
                    'username',
                    'email',
                    'phone',
                    'address'
                ]
            );

            const formData = new FormData();

            // Append form fields
            formData.append('username', userInput.username);
            formData.append('email', userData.email);
            formData.append('phone', userInput.phone);
            formData.append('address', userInput.address);
            formData.append('user_id', user_id);

            if (fileList.length > 0) {
                formData.append('profile_image', fileList[0]);
            }

            dispatch(UserService.userService(formData))
                .then((res) => {
                    console.info({ res });
                    if (res.success === true) {
                        closeModal();
                        form.resetFields();
                    } else {
                        console.log('error');
                    }
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err)
                    notification.error({
                        message: 'Something went wrong!'
                    })
                });
        } catch {
            console.log('Validation error')
        }

    };


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const props = {
        onRemove: (file) => {
            setFileList([]);
        },
        beforeUpload: (file) => {
            if (file.size <= 2000000) {
                setFileList([...fileList, file]);
                return false;
            } else {
                console.log('Failed');
            }
        },
        fileList,
    };

    return (
        <Form
            name="profileForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout='vertical'
            form={form}
        >
            <div className='row'>
                <div className='col-md-12'>
                    <Form.Item
                        name="username"
                        label="Username"
                        initialValue={userData ? userData.username : ''}
                    >
                        <Input
                            name="username"
                            className='form-control'
                            placeholder='Name'
                            onBlur={handleInput}
                            value={userInput.username}
                        />
                    </Form.Item>
                </div>
                <div className='col-md-6'>
                    <Form.Item
                        name="email"
                        label="Email"
                        initialValue={userData ? userData.email : ''}
                    >
                        <Input
                            name="email"
                            className='form-control'
                            placeholder='Email'
                            onBlur={handleInput}
                            value={userInput.email}
                            disabled
                        />
                    </Form.Item>
                </div>
                <div className='col-md-6'>
                    <Form.Item
                        name="phone"
                        label="Phone"
                        initialValue={userData ? userData.phone : ''}
                    >
                        <Input
                            name="phone"
                            className='form-control'
                            placeholder='Phone'
                            onBlur={handleInput}
                            value={userInput.phone}
                            maxLength={10}
                        />
                    </Form.Item>
                </div>
                <div className='col-md-12'>
                    <Form.Item
                        name="address"
                        label="Address"
                        initialValue={userData ? userData.address : ''}
                    >
                        <Input
                            name="address"
                            className='form-control'
                            placeholder='Address'
                            onBlur={handleInput}
                            value={userInput.address}
                        />
                    </Form.Item>
                </div>
                <div className='col-md-12'>
                    <Form.Item label="Profile Picture">
                        <Upload
                            className='uploadImage'
                            {...props}
                            accept="image/png, image/jpeg"
                            maxCount={1}
                        >
                            <button
                                style={{
                                    border: 0,
                                    background: 'none',
                                }}
                                type="button"
                            >
                                <PlusOutlined />
                                <div
                                    style={{
                                        marginTop: 8,
                                    }}
                                >
                                    Upload
                                </div>
                            </button>
                        </Upload>
                    </Form.Item>
                </div>
                <div className='col-md-12 text-center'>
                    <Button type="primary" htmlType="submit" className='submitButton'>
                        Update
                    </Button>
                </div>
            </div>
        </Form>
    )
}

export default ProfileForm;