import React, { useState } from "react";
import { Button, Form, Input, notification, Spin } from "antd";
import * as RegisterServices from "../../services/registerService";
import { useDispatch } from "react-redux";
import * as Actions from "../../store/actions/user";

import Swal from "sweetalert2";

const SignupForm = ({ onRegistrationSuccess }) => {
  var dispatch = useDispatch();
  const [form] = Form.useForm();

  const [loader, setLoader] = useState(false);


  const [submitting, setSubmitting] = useState(false);

  const [registerInput, setRegisterInput] = useState({
    username: "",
    phone: "",
    email: "",
    password: "",
  });

  const handleInput = (e) => {
    setRegisterInput({ ...registerInput, [e.target.name]: e.target.value });
  };

  const onFinish = async (e) => {
    setLoader(true);
    setSubmitting(true);
    try {
      await form.validateFields(["username", "phone", "email", "password"]);

      const data = {
        username: registerInput.username,
        phone: registerInput.phone_number,
        email: registerInput.email,
        password: registerInput.password,
      };

      dispatch(RegisterServices.register(data))
        .then((res) => {
            setLoader(false);
            notification.success({
                message: 'Registered Successfully!'
            })
          if (res.success === 1) {
            setSubmitting(false);
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("user_id", res.user.id);
            dispatch(Actions.set_user_auth(res));

            Swal.fire({
              icon: "success",
              title: "Successfully registered, please login",
              confirmButtonColor:"#348fef",
              confirmButtonText:"Login"
            }).then((result) => {
            });
          } else {
            setLoader(false);
            setSubmitting(false);
            console.log("error");
          }
          setRegisterInput({
            username: "",
            phone: "",
            email: "",
            password: "",
          });
          form.resetFields();
          onRegistrationSuccess();
        })
        .catch((err) => {
          setSubmitting(false);
          setLoader(false)
          console.log(err);
          notification.error({
            message: "The email has already been taken.",
          });
        });
    } catch {
      setLoader(false);
      console.log("Validation error");
    }
  };

  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value;
    const trimmedValue = inputValue.replace(/\D/g, "").slice(0, 10); // Remove non-digit characters and trim to 10 digits
    // Update the state or form field with the trimmed value
    // For example, if using state:
    setRegisterInput({
      ...registerInput,
      phone_number: trimmedValue,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Form
        name="SignupForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="signUpForm"
        form={form}
      >
        <h2>Sign up</h2>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input
            name="username"
            className="formControl"
            placeholder="Username"
            onBlur={handleInput}
            value={registerInput.username}
            prefix={<i className="fas fa-user" aria-hidden="true"></i>}
          />
        </Form.Item>

        <Form.Item
          name="phone"
          rules={[
            {
              pattern: /^\d{10}$/,
              message: "Please enter a valid 10-digit phone number",
            },
            {
              required: true,
              message: "Phone number is required",
            },
          ]}
        >
          <Input
            name="phone"
            className="formControl"
            placeholder="Phone number"
            onBlur={handleInput}
            onChange={handlePhoneNumberChange} // Add onChange event handler
            value={registerInput.phone_number}
            prefix={<i className="fa fa-phone" aria-hidden="true"></i>}
          />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Email is required",
            },
          ]}
        >
          <Input
            name="email"
            className="formControl"
            placeholder="Email"
            onBlur={handleInput}
            value={registerInput.email}
            prefix={<i className="fas fa-envelope" aria-hidden="true"></i>}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              type: "password",
              message: "The input is not valid Password!",
            },
            {
              required: true,
              message: "Password is required",
            },
            {
              message:
                "Password contain (8 characters , one symbol and one number)",
              pattern: new RegExp(
                /^(?=.*[a-z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/
              ),
            },
          ]}
        >
          <Input.Password
            name="password"
            className="formControl"
            placeholder="Password"
            onBlur={handleInput}
            value={registerInput.password}
            prefix={<i className="fas fa-lock" aria-hidden="true"></i>}
          />
        </Form.Item>

        <Form.Item>
          <Button
            disabled={submitting}
            type="primary"
            htmlType="submit"
            className="loginBtn"
          >
          {loader ? (<><Spin /> <p>Processing</p></>):(<>Sign Up</>)}
          
            {/* Sign up */}
          </Button>
        </Form.Item>
        {/* <p className='orTextRow'>
                Or Sign in with social platforms
            </p>
            <div className='socialLinks'>
                <a href="#" className="socialIcon">
                    <i className="fab fa-facebook-f" aria-hidden="true"></i>
                </a>
                <a href="#" className="socialIcon">
                    <i className="fab fa-twitter" aria-hidden="true"></i>
                </a>
                <a href="#" className="socialIcon">
                    <i className="fab fa-google" aria-hidden="true"></i>
                </a>
                <a href="#" className="socialIcon">
                    <i className="fab fa-linkedin-in" aria-hidden="true"></i>
                </a>
            </div> */}
      </Form>
    </>
  );
};

export default SignupForm;
