import React from "react";

const Terms = () => {
    return (
        <div className="policyWrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className='col-md-12 mb-5'>
                            <div className='sectionTitle'>
                                <h2><span>Terms And </span>&nbsp;Conditions</h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="policyContent">
                                <h3>Terms and Conditions</h3>
                                <p style={{color:"black"}}>Welcome to Rupezo! These terms and conditions outline the rules and regulations for the use of Rupezo's Website, located at <a href="https://www.rupezo.com/">https://www.rupezo.com/</a>.</p>
                                <p style={{color:"black"}}>By accessing this website we assume you accept these terms and conditions. Do not continue to use Rupezo if you do not agree to take all of the terms and conditions stated on this page.</p>
                                <h3>1. Cookies</h3>
                                <p style={{color:"black"}}>We employ the use of cookies. By accessing Rupezo, you agree to use cookies in agreement with Rupezo's Privacy Policy. Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies</p>
                                <h3>2. License</h3>
                                <p style={{color:"black"}}>Unless otherwise stated, Rupezo and/or its licensors own the intellectual property rights for all material on Rupezo. All intellectual property rights are reserved. You may access this from Rupezo for your personal use subject to restrictions set in these terms and conditions.</p>
                                <ul>
                                    <li>Republish material from rupezo</li>
                                    <li>Sell, rent, or sub-license material from rupezo</li>
                                    <li>Reproduce, duplicate, or copy material from rupezo</li>
                                    <li>Redistribute content from rupezo</li>
                                </ul>
                                <h3>3. Hyperlinking to our Content</h3>
                                <p style={{color:"black"}}>The following organizations may link to our Website without prior written approval:</p>
                                <ul>
                                    <li>Government agencies;</li>
                                    <li>Search engines;</li>
                                    <li>News organizations;</li>
                                    <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses;</li>
                                    <li>System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
                                </ul>
                                <p style={{color:"black"}}>These organizations may link to our home page, to publications, or other Website information so long as the link:</p>
                                <ul>
                                    <li>(a) is not in any way deceptive;</li>
                                    <li>(b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services;</li>
                                    <li>(c) fits within the context of the linking party's site.</li>
                                </ul>
                                <p style={{color:"black"}}>We may consider and approve other link requests from the following types of organizations:</p>
                                <ul>
                                    <li>commonly-known consumer and/or business information sources;</li>
                                    <li>dot.com community sites;</li>
                                    <li>associations or other groups representing charities;</li>
                                    <li>online directory distributors;</li>
                                    <li>internet portals;</li>
                                    <li>accounting, law, and consulting firms;</li>
                                    <li>educational institutions and trade associations.</li>
                                </ul>
                                <p style={{color:"black"}}>We will approve link requests from these organizations if we decide that:</p>
                                <ul>
                                    <li>(a) the link would not make us look unfavorably to ourselves or our accredited businesses;</li>
                                    <li>(b) the organization does not have any negative records with us;</li>
                                    <li>(c) the benefit to us from the visibility of the hyperlink compensates the absence of Rupezo;</li>
                                    <li>(d) the link is in the context of general resource information.</li>
                                </ul>
                                <p style={{color:"black"}}>These organizations may link to our home page so long as the link:</p>
                                <ul>
                                    <li>(a) is not in any way deceptive;</li>
                                    <li>(b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services;</li>
                                    <li>(c) fits within the context of the linking party's site.</li>
                                </ul>
                                <p style={{color:"black"}}>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Rupezo. Please include your name, your organization name, and contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>
                                <p style={{color:"black"}}>Approved organizations may hyperlink to our Website as follows:</p>
                                <ul>
                                    <li>By use of our corporate name;</li>
                                    <li>By use of the uniform resource locator being linked to;</li>
                                    <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.</li>
                                </ul>
                                <p style={{color:"black"}}>No use of Rupezo's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>
                                <h3>4. Disclaimer</h3>
                                <p style={{color:"black"}}>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will: limit or exclude our or your liability for death or personal injury; limit or exclude our or your liability for fraud or fraudulent misrepresentation; limit any of our or your liabilities in any way that is not permitted under applicable law; or exclude any of our or your liabilities that may not be excluded under applicable law. The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty. As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Terms;