import React from 'react';
import { useNavigate } from "react-router-dom";
import { CtaBg } from '../../constant/images'

const CTA = () => {
    let navigate = useNavigate();

    const handlenavigate = () => {
        navigate('/contact-us')
    }

    return (
        <section className='cta'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card ctaCard'>
                            <img src={CtaBg} alt="shape" className="ctaCardBg" />
                            <h2>Start Your Business WIth Us</h2>
                            <button className="btn btn-secondary" onClick={handlenavigate}>
                                <p className="btn-text">Contact Us</p>
                                <span className="square"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CTA;