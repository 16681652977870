import React, { useState, useEffect } from 'react';
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import * as AboutPageService from '../../services/aboutUsService';
import { useDispatch } from "react-redux";

const Instructor = () => {
    var dispatch = useDispatch();
    const [isContent, setIsContent] = useState([]);

    useEffect(() => {
        fetchAboutContent();
    }, []);

    const fetchAboutContent = () => {
        dispatch(AboutPageService.aboutUsPage())
            .then((res) => {
                setIsContent(res.data[0]);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    }

    return (
        <section className='instructor'>
            <div className='container'>
                {
                    isContent.team_data == [] ? '' :
                        <div className='row justify-content-center'>
                            <div className='col-md-12'>
                                <h2 className="sectionSubtitle">Board Of Director</h2>
                            </div>
                            {isContent.team_data && isContent.team_data.map((team, index) => {
                                return (
                                    <div className='col-lg-3 col-md-6' key={index}>
                                        <div className="card instructorCard">
                                            <div className="instructorImgBox">
                                                <img src={team.team_image} alt="instructor" />
                                                <div className="socialLink">
                                                    <a href={team.team_fb} className="facebook">
                                                        <FaFacebook />
                                                    </a>

                                                    <a href={team.team_instagram} className="instagram">
                                                        <FaInstagram />
                                                    </a>

                                                    <a href={team.team_twitter} className="twitter">
                                                        <FaXTwitter />
                                                    </a>
                                                </div>
                                            </div>
                                            <h4 className="instructorName">{team.team_title}</h4>
                                            <p className="instructorTitle">{team.team_description}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                }
            </div>
        </section>
    )
}

export default Instructor;