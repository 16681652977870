import React, { useState } from 'react';
import LoginForm from './loginForm.js'
import SignUpForm from './signupForm.js'
import { LoginImg, SignUpImg } from '../../constant/images';

const Auth = () => {
    const [isSignUpClicked, setIsSignUpClicked] = useState(false);

    const handleSignUpClick = () => {
        setIsSignUpClicked(true);
    };

    const handleLogInClick = () => {
        setIsSignUpClicked(false);
    };

    return (
        <div className={`authWrapper ${isSignUpClicked ? 'signedUp' : ''}`}>
            <div className="formPanel">
                <div className="loginSignup">
                    <LoginForm />
                    <SignUpForm 
                        onRegistrationSuccess={handleLogInClick}
                    />
                </div>
            </div>
            <div className="panel">
                <div className="innerPanel leftPanel">
                    <div className="panelHeading">
                        <h3>New Here ?</h3>
                        <p>
                            Join us and start your journey by creating an account today
                        </p>
                        <button className="btn transparent" id="sign-up-btn" onClick={handleSignUpClick}>
                            Sign up
                        </button>
                    </div>
                    <div className="imgBox">
                        <img src={LoginImg} className="img-fluid image" alt="login" />
                    </div>
                </div>
                <div className="innerPanel rightPanel">
                    <div className="panelHeading">
                        <h3>One of us ?</h3>
                        <p>
                            Welcome back! Please enter your credentials to access your account
                        </p>
                        <button className="btn transparent" id="sign-up-btn" onClick={handleLogInClick}>
                            Sign In
                        </button>
                    </div>
                    <div className="imgBox">
                        <img src={SignUpImg} className="img-fluid image" alt="Signup" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Auth;