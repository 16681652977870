import React, { useState } from "react";
import { Button, Form, Input, notification } from 'antd';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as ResetPasswordServices from "../../services/resetPasswordService";

const ResetForm = () => {
    const [form] = Form.useForm();
    let navigate = useNavigate();
    var dispatch = useDispatch();

    const [resetInput, setResetInput] = useState({
        email: "",
        password: "",
        confirmPassword: "",
    });

    const handleInput = (e) => {
        setResetInput({ ...resetInput, [e.target.name]: e.target.value });
    };

    const onFinish = async (e) => {
        try {
            await form.validateFields(
                [
                    'email',
                    'password',
                    'confirmPassword',
                ]
            );

            const data = {
                email: resetInput.email,
                password: resetInput.password,
                confirmPassword: resetInput.confirmPassword,
            };


            dispatch(ResetPasswordServices.resetPassword(data))
                .then((res) => {
                    form.resetFields();
                    notification.success({
                        message: 'Your password has been updated successfully!'
                    })
                    navigate('/signup')
                })
                .catch((err) => {
                    console.log(err)
                    notification.error({
                        message: 'Something went wrong!'
                    })
                });
        } catch {
            console.log('Validation error')
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            name="resetForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className='ForgotForm'
            form={form}
        >
            <h2>Reset Your Password</h2>
            <Form.Item
                name="email"
                rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Email is required',
                    },
                ]}
            >
                <Input
                    name="email"
                    className='formControl'
                    onBlur={handleInput}
                    value={resetInput.email}
                    placeholder='Email'
                    prefix={<i className="fas fa-user" aria-hidden="true"></i>}
                />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[
                    {
                        type: 'password',
                        message: 'The input is not valid Password!',
                    },
                    {
                        required: true,
                        message: 'Password is required',
                    },
                    {
                        message: 'Password contain (8 characters , one symbol and one number)',
                        pattern: new RegExp(/^(?=.*[a-z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/)
                    }
                ]}
            >
                <Input.Password
                    name="password"
                    className='formControl'
                    onBlur={handleInput}
                    value={resetInput.password}
                    placeholder='Password'
                    prefix={<i className="fas fa-lock" aria-hidden="true"></i>}
                />
            </Form.Item>

            <Form.Item
                name="confirmPassword"
                dependencies={['password']}
                rules={[
                    {
                        required: true,
                        message: 'Confirm Password is required',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The new password that you entered do not match!'));
                        },
                    }),
                ]}
            >
                <Input.Password
                    name="confirmPassword"
                    className='formControl'
                    onBlur={handleInput}
                    value={resetInput.confirmPassword}
                    placeholder='Confirm Password'
                    prefix={<i className="fas fa-lock" aria-hidden="true"></i>}
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" className='loginBtn'>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

export default ResetForm;