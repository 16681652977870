import React, { useState, useEffect } from "react";
import { FaMobileScreenButton, FaEnvelopeOpen, FaLocationDot, FaRegClock } from "react-icons/fa6";
import { ContactImg } from '../../constant/images';
import * as ContactDataSerivce from '../../services/contactService';
import { useDispatch } from "react-redux";
import ContactForm from '../../components/ContactForm';

const ContactSection = () => {
    var dispatch = useDispatch();
    const [isData, setIsData] = useState({});

    const contactStyle = {
        paddingTop: '2rem',
        marginBottom: '4rem'
    };

    useEffect(() => {
        fetchContactData();
    }, []);

    const fetchContactData = () => {
        dispatch(ContactDataSerivce.contactData())
            .then((res) => {
                setIsData(res.data[0]);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    }

    return (
        <>
            <section className='contactSection pb-2'>
                <div className='container'>
                    <div className='contactInfo'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='sectionTitle'>
                                    <h2><span>Contac</span>t Us</h2>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='contactInfo' style={contactStyle}>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='infoCol'>
                                                <div className='iconWrap'>
                                                    <FaMobileScreenButton />
                                                </div>
                                                <h3 className='inofTitle'>Phone No.</h3>
                                                {isData.phone && JSON.parse(isData.phone)[0] && (
                                                    <p className='info'>{JSON.parse(isData.phone)[0]}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='infoCol'>
                                                <div className='iconWrap'>
                                                    <FaEnvelopeOpen />
                                                </div>
                                                <h3 className='inofTitle'>E-mail</h3>
                                                <p className='info'>{isData.email}</p>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='infoCol'>
                                                <div className='iconWrap'>
                                                    <FaLocationDot />
                                                </div>
                                                <h3 className='inofTitle'>Address</h3>
                                                <p className='info'>{isData.address}</p>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='infoCol'>
                                                <div className='iconWrap'>
                                                    <FaRegClock />
                                                </div>
                                                <h3 className='inofTitle'>Opening Hours</h3>
                                                <p className='info'>{isData.opening_hours}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='contactForm'>
                        <div className='row'>
                            <div className='col-md-6 d-flex justify-content-center align-items-center'>
                                <div className='formSection'>
                                    <ContactForm />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='contactImageWrap'>
                                    <div className='imageWrap'>
                                        <img src={ContactImg} className='img-fluid' alt='logo' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mapSection'>
                <div dangerouslySetInnerHTML={{ __html: isData.iframe_maps }} />
            </section>
        </>
    )
}

export default ContactSection;