import Http from "../Http";

export function resetPassword(values) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post('api/updatePassword', values)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function sendPasswordLink(values) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post('api/sendResetPasswordEmail', values)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}