import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaRegCalendarAlt, FaUser } from "react-icons/fa";
import { Input, Spin } from 'antd';
import { useDispatch } from "react-redux";
import * as EventService from '../../services/eventsService';
import CTA from '../../components/CTA';

const { Search } = Input;

const EventsDetail = () => {
    const onSearch = (value, _e, info) => console.log(info?.source, value);
    var dispatch = useDispatch();
    const { id } = useParams();
    const [eventDetails, setEventDetails] = useState(null);
    const [isContent, setIsContent] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchEventContent(id);
        fetchEventList();
    }, []);

    const fetchEventContent = () => {
        dispatch(EventService.events(id))
            .then((res) => {
                const events = res.data.find(events => events.id === parseInt(id));
                setEventDetails(events);
                setIsLoading(false);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
                setIsLoading(false);
            });
    }

    const fetchEventList = () => {
        dispatch(EventService.events())
            .then((res) => {
                setIsContent(res.data);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    }

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options);
    };

    return (
        <>
            {
                isLoading ?
                    <div className='overlay'>
                        <Spin size='large' />
                    </div> :
                    <>
                        <div className='eventDetailsWrapper'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-8'>
                                        <div className='eventImage'>
                                            <img src={eventDetails.images} className='img-fluid' alt='eventImgae' />
                                        </div>

                                        <div className='eventContent'>
                                            <ul className='eventMetaData'>
                                                <li>
                                                    <FaUser /> Admin
                                                </li>
                                                <li>
                                                    <FaRegCalendarAlt /> {formatDate(eventDetails.event_date)}
                                                </li>
                                            </ul>
                                            <div className='eventTitle'>
                                                <h2>{eventDetails.event_name}</h2>
                                            </div>
                                            <p dangerouslySetInnerHTML={{ __html: eventDetails.description }} />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='upcomingEvents'>
                                            {/* <div className='card upcomingEventCard mb-4'>
                                                <div className='card-body'>
                                                    <Search
                                                        placeholder="Search..."
                                                        allowClear
                                                        enterButton="Search"
                                                        size="large"
                                                        onSearch={onSearch}
                                                    />
                                                </div>
                                            </div> */}
                                            <div className='card upcomingEventCard'>
                                                <div className='card-header'>
                                                    <h4 className='card-title'>
                                                        Upcoming Events
                                                    </h4>
                                                </div>
                                                <div className='card-body'>
                                                    <div className='upcomingEventsList'>
                                                        {isContent.map((eventItem) => (
                                                            <div className='upcomingEventItem' key={eventItem.id}>
                                                                <div className='imgWrap'>
                                                                    <img src={eventItem.images} className="img-fluid" alt='blogs' />
                                                                </div>
                                                                <div className='upcomingEventMeta'>
                                                                    <p><FaRegCalendarAlt /> {formatDate(eventItem.event_date)}</p>
                                                                    <a href={`/event-details/${eventItem.id}`} className='upcomingLink'>{eventItem.event_name}</a>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CTA />
                    </>
            }
        </>
    )
}

export default EventsDetail;