import React, { useState, useEffect, useRef } from "react";
import {
    CourseImgOne,
    CourseImgTwo,
    CourseImgThree,
    CourseImgFour,
    CourseImgFive,
    CourseImgSix
} from '../../constant/images';
import * as ServicePage from '../../services/servicesPage';
import { useDispatch } from "react-redux";

const Services = () => {
    var dispatch = useDispatch();
    const serviceSectionRef = useRef(null);
    const [isContent, setIsContent] = useState([]);

    useEffect(() => {
        fetchServiceContent();
    }, []);

    useEffect(()=>{
       const scroll =  localStorage.getItem("scrollTo")
       localStorage.removeItem("scrollTo")
       if(scroll !== null && scroll=== "services"){
        document.querySelector("#services").scrollIntoView()
       }
    },[])

    const fetchServiceContent = () => {
        dispatch(ServicePage.servicePage())
            .then((res) => {
                setIsContent(res.data);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    }

    function TruncateDescription(description, limit) {
        if (description.length <= limit) {
            return description;
        }
        return description.slice(0, limit) + '.';
    }

    return (
        <section className='services' id="services" ref={serviceSectionRef}>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='sectionTitle'>
                            <h2><span>Serv</span>ices</h2>
                        </div>
                        <div className='sectionText'>
                            <h2>Exceptional
                                <a href="/"> <strong> Startup
                                    Business</strong>
                                </a>
                            </h2>
                        </div>
                    </div>
                    
                    {isContent.slice(0, 6).map((item, index) => {
                        return (
                            <div className='col-md-6 col-lg-4' key={index}>
                                <div className='courseGrid'>
                                    <div className='card courseCard'>
                                        <div className='courseImgBox'>
                                            <img src={
                                                index === 0 ? CourseImgOne :
                                                index === 1 ? CourseImgTwo :
                                                index === 2 ? CourseImgThree :
                                                index === 3 ? CourseImgFour :
                                                index === 4 ? CourseImgFive : CourseImgSix
                                            } className='courseImg img-fluid' alt='image' />
                                        </div>
                                        <div className='courseContent'>
                                            <h3 className='cardTitle'>{item.top_heading}</h3>
                                            <p dangerouslySetInnerHTML={{ __html: TruncateDescription(item.banner_description, 100)}} />
                                            <div className="cardFooter">
                                                <a href={`/services/${item?.top_heading}`}>Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Services;