import React from 'react';
import { Logo } from '../../constant/images';
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Index = () => {

    const setServiceScroll = () =>{
         localStorage.setItem("scrollTo","services")
         setTimeout(()=>{
            localStorage.removeItem("scrollTo")
         },2000)
    }
    return (
        <footer>
            <div className="footer-grid">
                <div className='row'>
                    <div className='col-lg-4 col-md-6 mb-3'>
                        <div className="grid-item">
                            <div className="footer-logo">
                                <img className="rupezo-logo" src={Logo} alt="alpha97 logo white" />
                            </div>
                            <p className="footer-text">
                                Rupezo is a dynamic financial services company, committed to empowering individuals and
                                businesses with innovative financial&nbsp;solutions.
                            </p>

                            <div className="social-link">
                                <a
                                    href="https://www.facebook.com/profile.php?id=100094721979678" target="_blank" rel="noreferrer">
                                    <FaFacebook />
                                </a>
                                <a href="https://www.instagram.com/rupezo_finserv_inc/" target="_blank" rel="noreferrer">
                                    <FaInstagram />
                                </a>
                                <a href="https://twitter.com/RUPEZO149002" target="_blank" rel="noreferrer">
                                    <FaXTwitter />
                                </a>
                                <a href="https://www.youtube.com/channel/UCHvPT0WU0aNc6CZRtcb6pNw" target="_blank" rel="noreferrer">
                                    <FaYoutube />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-6 mb-3'>
                        <ul className="grid-item">
                            <h4 className="item-heading">Our Links</h4>
                            <li className="list-item">
                                <a href="/">Home</a>
                            </li>

                            <li className="list-item">
                                <a href="/about-us">About Us</a>
                            </li>

                            <li className="list-item" onClick={()=> setServiceScroll()}>
                                <a href="/#services">Services</a>
                            </li>

                            <li className="list-item">
                                <a href="/blogs">Blog</a>
                            </li>

                            <li className="list-item">
                                <a href="/contact-us">Contact</a>
                            </li>
                        </ul>
                    </div>
                    <div className='col-lg-2 col-md-6'>
                        <ul className="grid-item">
                            <h4 className="item-heading">Other Links</h4>
                            {/* <li className="list-item">
                                <a href="/">Board Of Directors</a>
                            </li> */}

                            <li className="list-item">
                                <a href="/faq">FAQs</a>
                            </li>

                            {/* <li className="list-item">
                                <a href="/events">Events</a>
                            </li> */}

                            <li className="list-item">
                                <a href="/privacy-policy">Privacy Policy</a>
                            </li>

                            <li className="list-item">
                                <a href="/terms">Term &amp; Condition</a>
                            </li>

                            <li className="list-item">
                                <a href="/return-policy">Return Policy</a>
                            </li>

                        </ul>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className="cont-address">
                            <h2>Our Address</h2>
                            <div className="contactDetails">
                                <i className="ri-map-pin-line"> </i>
                                <a href="https://www.google.com/maps/place/Rupezo/@30.6566341,76.8556919,15z/data=!4m6!3m5!1s0x390f958531f7c549:0x67bc5d95e17bd0c!8m2!3d30.6566341!4d76.8556919!16s%2Fg%2F11vqg_srf4?entry=ttu" target="_blank" rel="noreferrer">RUPEZO FINSEERV INC, <br />

                                    Sco 10,Marigold spaces opp. Panchkula heights, Peermuchalla, Zirakpur
                                </a>
                            </div>
                            <div className="contactDetails">
                                <i className="ri-mail-fill"></i> <a href="mailto: info@rupezo.com">info@rupezo.com</a>
                            </div>
                            <div className="contactDetails">
                                <i className="ri-phone-fill"></i> <a href="tel:+918559055905">+91 8559055905</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-12'>
                <p className="copyright">
                    Copyright © 2023-24 Rupezo. All rights reserved.
                </p>
            </div>
        </footer>
    )
}

export default Index;