import React, { useState, useEffect } from "react";
import { FaMobileScreenButton, FaEnvelopeOpen, FaLocationDot, FaRegClock } from "react-icons/fa6";
import { ContactImg } from '../../constant/images';
import ContactForm from "../../components/ContactForm";
import { useDispatch } from "react-redux";
import { Spin } from 'antd';
import * as ContactDataSerivce from '../../services/contactService';

const ContactUs = () => {
    var dispatch = useDispatch();
    const [isData, setIsData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchContactData();
    }, []);

    const fetchContactData = () => {
        dispatch(ContactDataSerivce.contactData())
            .then((res) => {
                setIsData(res.data[0]);
                setIsLoading(false);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
                setIsLoading(false)
            });
    }

    return (
        <>
            {
                isLoading ?
                    <div className="overlay">
                        <Spin size="large" />
                    </div> :
                    <>
                        <section className='contactSection'>
                            <div className='container'>
                                <div className='contactForm'>
                                    <div className='row'>
                                        <div className='col-lg-6 order2'>
                                            <div className='formSection'>
                                                <div className='sectionTitle'>
                                                    <h2><span>Contac</span>t Us</h2>
                                                </div>
                                                <h2 className='subTitle'>
                                                    Get in Touch with Us
                                                </h2>
                                                <ContactForm />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 order1'>
                                            <div className='contactImageWrap'>
                                                <div className='imageWrap'>
                                                    <img src={ContactImg} className='img-fluid' alt='logo' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='contactInfo'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='contactInfo'>
                                                <div className='row'>
                                                    <div className='col-lg-3 col-md-6'>
                                                        <div className='infoCol'>
                                                            <div className='iconWrap'>
                                                                <FaMobileScreenButton />
                                                            </div>
                                                            <h3 className='inofTitle'>Phone No.</h3>
                                                            {isData.phone && JSON.parse(isData.phone)[0] && (
                                                                <p className='info'>{JSON.parse(isData.phone)[0]}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-3 col-md-6'>
                                                        <div className='infoCol'>
                                                            <div className='iconWrap'>
                                                                <FaEnvelopeOpen />
                                                            </div>
                                                            <h3 className='inofTitle'>E-mail</h3>
                                                            <p className='info'>{isData.email}</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-3 col-md-6'>
                                                        <div className='infoCol'>
                                                            <div className='iconWrap'>
                                                                <FaLocationDot />
                                                            </div>
                                                            <h3 className='inofTitle'>Address</h3>
                                                            <p className='info'>{isData.address}</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-3 col-md-6'>
                                                        <div className='infoCol'>
                                                            <div className='iconWrap'>
                                                                <FaRegClock />
                                                            </div>
                                                            <h3 className='inofTitle'>Opening Hours</h3>
                                                            <p className='info'>{isData.opening_hours}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='mapSection'>
                            <div dangerouslySetInnerHTML={{ __html: isData.iframe_maps }} />
                        </section>
                    </>
            }
        </>

    )
}

export default ContactUs;