import React from 'react';
import { Quote } from '../../constant/images' 

const Testimonial = (props) => {
    return (
        <div className='testimonialWrapper'>
            <div className="spikes"></div>
            <section className='testimonial'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6' style={{margin: 'auto'}}>
                            <div className='testimonialLeft'>
                                <div className='sectionTitle'>
                                    <h2><span>Testim</span>onials</h2>
                                </div>
                                <h2 className='subTitle'>
                                    {props.subTitle}
                                </h2>
                                <p className='sectionText' dangerouslySetInnerHTML={{ __html: props.sectionText }}></p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='testimonialsRight'>
                                <div className="testimonialsCard">
                                    <img src={Quote} alt="quote icon" className="quote-img" />
                                    <p className="testimonialsText">
                                        "Rupezo's GST registration services have been invaluable for my business. Their
                                        knowledgeable team guided me through the process seamlessly, saving time and ensuring
                                        compliance. Rupezo made the registration and <strong>goods and service tax login</strong> easy,
                                        and I now have peace of mind knowing my
                                        business is on the right track. Highly&nbsp;recommended!"
                                    </p>
                                    <div className="testimonialsClient">
                                        <div className="clientImgBox">
                                            <img src={props.CustomerImg} alt="client" />
                                        </div>
                                        <div className="clientDetail">
                                            <h4 className="clientName">{props.CustomerName}</h4>
                                            <p className="clientTitle">{props.CustomerTitle}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Testimonial;